<template>
  <div id="loading-bg">
    <div class="loading-logo">
      <img src="@/assets/images/logo/logo.png" alt="Logo">
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios.js'
import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'

export default {
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getUserByEmail: 'getUserByEmail',
      getUserAuthorizedPortalsMenuList: 'getUserAuthorizedPortalsMenuList',
      addNewUserFromAuth0: 'addNewUserFromAuth0',
      getUserGlobalCaps: 'getUserGlobalCaps',
      updateUserSsoId: 'updateUserSsoId',
      syncWithAuth0: 'syncWithAuth0',

      // ROYBON / Parcours de connexion
      userData: {},
      portals: {},
      redirectTo: '',
      AppActiveUserDispatched : false,
      PortalsListDispatched : false,

      // ROYBON / Profil RIM-Nat du user
      user_id: null,
      user_hash: null,
      first_name    : '',
      last_name     : '',
      user_email    : '',
      user_lang     : '',
      sso_id        : '',
      is_new_user   : null,
      email_verified   : null
    }
  },
  methods: {
    // ROYBON / Importe un avatar en provenance d'un profil social
    importSocialAvatar () {
      const avatarUrl = this.userData.profile.picture
      const socialAvatarIncludeGravatar = avatarUrl.includes('gravatar')
      if (socialAvatarIncludeGravatar === false) {
        if (!isNaN(this.user_id && avatarUrl !== '' && !socialAvatarIncludeGravatar)) {
          const params = `user_id=${this.user_id}&url=${this.userData.profile.picture}`
          const rimnat_api_call_url = `${this.$appConfig.imgBaseUrl}/import_avatar.php?${params}`
          axios.get(rimnat_api_call_url, {
          })
            .then(() => { 
            })
            .catch((error)   => { console.log(error) })
        }
      }
    },

    
    syncWithApi () {
      const params = new FormData()
      params.set('token', `${this.$store.state.AppActiveUser.encTk}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.syncWithAuth0}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response !== null) {
            if (response.data !== null  && response.data != 'null') {
              this.user_id    = parseInt(response.data.user_id)
              const string_user_id = this.user_id.toString()
              const sha1_user_id = sha1(string_user_id).toString(CryptoJS.enc.sha1)
              const md5_sha1_user_id = md5(sha1_user_id).toString(CryptoJS.enc.md5)
              this.user_hash = md5_sha1_user_id

              if (Number.isInteger(this.user_id)) {
                this.first_name       = response.data.first_name
                this.last_name        = response.data.last_name
                this.user_email       = response.data.user_email
                this.sso_id           = response.data.sso_id
                this.user_lang        = response.data.user_lang
                this.is_new_user      = response.data.is_new_user
                this.dispatchUser()
              }
            }
          }
        })
        .catch((error)   => { console.log(error) })
      
    },    

    // ROYBON / Intercepte le retour de Auth0
    handleLoginEvent (data) {
      // console.log(data)
      this.userData = data

      // Sync with api
      this.syncWithApi()

    },

    // ROYBON / Propage le user dans le store
    dispatchUser () {
      const displayName = `${this.first_name} ${this.last_name}`
      this.$store.dispatch('updateUserInfo', {
        displayName: displayName,
        email: this.user_email,
        photoURL: '', // photoURL: this.userData.profile.picture,
        providerId: this.userData.profile.sub.substr(0, this.userData.profile.sub.indexOf('|')),
        uid: this.userData.profile.sub,
        user_id: this.user_id,
        user_hash: this.user_hash,
        first_name: this.first_name,
        last_name: this.last_name, 
        user_email: this.user_email,
        user_lang: this.user_lang,
        is_new_user: this.is_new_user,
        email_verified: this.userData.profile.email_verified
      })
      this.AppActiveUserDispatched = true
    },

    // ROYBON / Update de la liste des portals
    updatePortalList () {
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/getUserAuthorizedPortalsMenuList?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response !== null) {
            if (response.data !== null && response.data != 'null') {
              this.portals = response.data
            } else {
              this.portals = null
            }
            this.$store.commit('UPDATE_PORTALS', this.portals)
            this.$store.dispatch('updatePortals', this.portals)
            this.PortalsListDispatched = true
          }
        })
        .catch((error)   => { console.log(error) })
    },
    
    // ROYBON / Get global_caps du user
    updateUserGlobalCaps () {
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserGlobalCaps}?${params}&time=${this.currentTimestamp}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.current_user_global_caps = response.data 
            this.$store.commit('UPDATE_CURRENT_USER_GLOBAL_CAPS', this.current_user_global_caps)
            this.$store.dispatch('updateCurrentUserGlobalCaps', this.current_user_global_caps)
          }
        })
        .catch((error)   => { console.log(error) })
    }

  },
  created () {
    this.$auth.handleAuthentication()
    if (this.$auth.isAuthenticated()) {
      this.$router.push('/').catch(() => {})
    }
  },
  watch: {
    user_lang: function () {
      if (typeof this.user_lang === 'string' && this.user_lang !== '' && this.user_lang !== null) {
        this.$i18n.locale = this.user_lang.toString().toLowerCase().trim()
      }
    },
    user_id: function () {
      if (this.user_id != null) {
        this.importSocialAvatar()
        this.dispatchUser()
        logActivity.add('app_login', 'success')
      }
    },
    AppActiveUserDispatched: function () {
      if (this.AppActiveUserDispatched == true) {
        this.updatePortalList()
        if (this.is_new_user == '1') {
          this.$router.push('/').catch(() => {})
        }
      }
    },
    PortalsListDispatched: function () {
      if (this.PortalsListDispatched == true) {
        this.updateUserGlobalCaps()
      }
    },
    portals: function () {
      const storedRedirect = sessionStorage.getItem('loginRedirect')
      sessionStorage.removeItem('loginRedirect')
      if (storedRedirect !== '/' && storedRedirect !== null && storedRedirect !== '') {
        this.redirectTo = storedRedirect
      } else {
        this.redirectTo = '/'
      }
    },
    redirectTo: function () {
      this.$router.push(`${this.redirectTo}`).catch(() => {})
    }
  }
}

</script>

<style lang="scss">
@import "../assets/css/loader.css";
</style>
